<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>学术系统</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_referee_system',
                }"
                >裁判系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>复活赛排行</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-row :gutter="15">
            <el-col :span="16">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span class="title_class">信息筛选</span>
                        </el-col>
                    </el-row>
                    <el-form
                        :model="queryForm"
                        :rules="matchFormRules"
                        class="filter_Form"
                        label-width="100px"
                        ref="queryFormRef"
                    >
                        <el-row :gutter="10">
                            <el-col :span="10">
                                <el-form-item label="赛季" prop="season">
                                    <el-select
                                        placeholder="请选择赛季"
                                        style="width: 100%"
                                        v-model="queryForm.season"
                                    >
                                        <el-option
                                            v-for="item in dict_season"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="组别" prop="level">
                                    <el-select
                                        v-model="queryForm.level"
                                        @change="levelChange"
                                        placeholder="请选择"
                                        style="width: 100%"
                                        clearable
                                    >
                                        <el-option
                                            v-for="item in dict_Group"
                                            :key="item.dictCode"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-row :gutter="20" type="flex" justify="end">
                        <el-button type="primary" @click="search"
                            >查询</el-button
                        >
                        <el-button type="primary" @click="reset"
                            >重置</el-button
                        >
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col :span="24">
                            <span class="title_class">大屏操作<span style="color: red; font-size: 12px;">(*大屏链接请先选择组别)</span></span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button
                                :disabled="queryForm.level === ''"
                                type="warning"
                                plain
                                round
                                @click="copyScreenLink('false')"
                                icon="el-icon-link"
                                >组别大屏链接</el-button
                            >
                            <el-button
                                :disabled="queryForm.level === ''"
                                type="primary"
                                plain
                                round
                                @click="copyScreenLink('true')"
                                icon="el-icon-link"
                                >轮播大屏链接</el-button
                            >
                        </el-col>
                        <el-col :span="24" style="margin-top: 21px">
                            <el-button
                                type="success"
                                plain
                                round
                                @click="addExamineeDialogVisible = true"
                                icon="el-icon-plus"
                                >新增复活赛选手</el-button
                            >
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-card style="margin-top: 15px">
            <!-- <el-row class="title_row" align="middle" type="flex">
                <el-col :span="6">
                    <span class="title_class">复活赛成绩</span>
                </el-col>
                <el-col :span="18" style="text-align: right">
                    <el-button type="primary" @click="exportFn"
                        >导出成绩</el-button
                    >
                </el-col>
            </el-row> -->
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column
                    label="出场顺序"
                    prop="competitionNumber"
                ></el-table-column>
                <el-table-column label="姓名" prop="userName"></el-table-column>
                <el-table-column label="国赛号码牌" prop="nationalnum">
                    <template scope="scope">
                        <span style="color: red; font-size: 20px">{{
                            scope.row.nationalnum
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="英文名" prop="enName"></el-table-column>
                <el-table-column
                    label="手机号"
                    prop="phone"
                    width="100px"
                ></el-table-column>
                <!-- <el-table-column label="性别" prop="sex">
                    <template slot-scope="scope">
                        {{
                            scope.row.sex == 1
                                ? "男"
                                : scope.row.sex == 0
                                ? "女"
                                : "未设置"
                        }}
                    </template>
                </el-table-column> -->
                <el-table-column label="积分" prop="integral">
                    <template scope="scope">
                        <span style="color: red; font-size: 20px">{{
                            scope.row.integral
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="城市赛名次" prop="rankingName">
                    <template scope="scope">
                        <span style="color: green; font-size: 20px">{{
                            scope.row.rankingName
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="答题秒数" prop="seconds">
                    <template scope="scope">
                        <span style="color: black; font-size: 20px"
                            >{{ scope.row.seconds }}s</span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="是否晋级" prop="promoted">
                    <template slot-scope="scope">
                        <el-tag
                            size="mini"
                            :type="
                                scope.row.promoted === 1
                                    ? 'success'
                                    : scope.row.promoted === 0
                                    ? 'danger'
                                    : 'info'
                            "
                            >{{
                                scope.row.promoted === 1
                                    ? "晋级"
                                    : scope.row.promoted === 0
                                    ? "淘汰"
                                    : "未设置"
                            }}</el-tag
                        >
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    label="学校"
                    prop="school"
                    width="180"
                ></el-table-column>
                <el-table-column
                    label="年级"
                    prop="grade"
                    :formatter="gradeFormatter"
                ></el-table-column> -->
                <!-- <el-table-column label="班级" prop="classes"></el-table-column> -->
                <!-- <el-table-column label="操作" width="120px" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="showDetail(scope.row)"
                            >查看详情</el-button
                        >
                    </template>
                </el-table-column> -->
            </el-table>
        </el-card>
            <!-- 添加考生的Dialog -->
    <el-dialog
      title="添加考生"
      :visible.sync="addExamineeDialogVisible"
      width="35%"
      @close="closeAddExamineeDialog()"
    >
      <el-form
        :model="addExamineeForm"
        ref="addExamineeFormRef"
        :rules="addExamineeFormRules"
        label-width="120px"
      >
        <el-form-item label="考生手机号" prop="code">
          <el-input
            v-model="addExamineeForm.code"
            placeholder="请输入考生手机号"
            style="width: 80%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addExamineeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAddExamineeClick"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    </div>
</template>
<script>
import { getNationalScreenData, resurrectionSignBrainless } from '@/http/api'

export default {
  data () {
    return {
      addExamineeForm: {
        code: null
      },
      addExamineeFormRules: {
        code: [{ required: true, message: '请填写手机号', trigger: 'blur' }]
      },
      addExamineeDialogVisible: false,
      dict_district: [],
      dict_season: [],
      dict_grade: this.$userInfo.getGradeData(),
      queryForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        level: ''
      },
      groupStr: '',
      dataList: [],
      dict_Group: this.$userInfo.dict_group(),
      matchFormRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  created () {
    this.getSeason()
  },
  methods: {
    // 关闭添加考生弹窗
    closeAddExamineeDialog () {
      this.$refs.addExamineeFormRef.resetFields()
    },
    // 保存添加考生
    saveAddExamineeClick () {
      this.$refs.addExamineeFormRef.validate(async valid => {
        if (!valid) return
        resurrectionSignBrainless(this.addExamineeForm).then(res => {
          if (res.code === 200) {
            this.addExamineeDialogVisible = false
            this.$message.success('保存成功')
            this.getDataList()
          }
        })
      })
    },
    goBack () {
      this.$router.back()
    },
    getSeason () {
      this.$userInfo.getDataList(this, 't_season').then(value => {
        this.dict_season = value
      })
    },
    getDataList () {
      getNationalScreenData(this.queryForm).then(res => {
        console.log('res', res)
        if (res) {
          if (res.code === 200) {
            this.dataList = res.data
          } else {
            this.dataList = []
            this.$message.error(res.msg)
          }
        } else {
          this.dataList = []
        }
      })
    },
    search () {
      this.getDataList()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    levelChange (value) {
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === value) {
            this.groupStr = element.dictLabel
          }
          console.log('this.groupStr', this.groupStr)
        }
      }
    },
    // code
    copyScreenLink (isCarousel) {
      var input = document.createElement('input') // 创建input标签
      var location = window.location.origin//   input.value = `${location}/#/screen/finalScreenRank?season=${this.queryForm.season}&level=${this.queryForm.level}&groupStr=${this.groupStr}&isCarousel=${isCarousel}`
      document.body.appendChild(input) // 添加input标签
      input.select() // 选中input标签
      document.execCommand('copy') // 执行复制
      this.$message.success('复制成功') // 成功提示信息
      document.body.removeChild(input) // 移除input标签
      window.open(`${location}/#/screen/finalScreenRank?season=${this.queryForm.season}&level=${this.queryForm.level}&groupStr=${this.groupStr}&isCarousel=${isCarousel}`, '_blank')
    }
  }
}
</script>

<style scoped lang="less">
.h1 {
    color: #000;
    margin-top: 20px;
}
.h2 {
    color: #000;
}
.total {
    font-size: 16px;
    color: #000;
    span {
        color: #409eff;
    }
}
.font {
    margin-right: 12px;
}

.answer-img {
    width: 40px;
}
</style>
